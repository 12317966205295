


















































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { ClaimerPoolsViewModel } from '../viewmodels/claimer-pools-viewmodel'

@Observer
@Component({
  components: {
    'claimer-pool-item': () => import('../components/claimer-pool-item.vue'),
    'add-winner-list-dialog': () => import('../dialogs/add-winner-list-dialog.vue')
  }
})
export default class Claimer extends Vue {
  @Provide() vm = new ClaimerPoolsViewModel()
  walletStore = walletStore
  destroyed() {
    this.vm.destroy()
  }
}
