import { observable, reaction, action, IReactionDisposer, computed } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { walletStore } from '@/stores/wallet-store'
import { blockchainHandler } from '@/blockchainHandlers'
import { SolidityMultiClaimContract } from '@/blockchainHandlers/multiclaim-contract-solidity'
import { FULL_100, Zero } from '@/constants'
import { FixedNumber } from '@ethersproject/bignumber'
import { orderBy } from 'lodash'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
export class ClaimerPoolsViewModel {
  @observable claims = []
  @observable isShowAddWinnerListDialog = false
  @observable addClaimDialogLoading = false
  @observable remainingFilter = false
  @observable completedFilter = false
  @observable isShortAscCreatedDate = false

  multiclaimHandler?: SolidityMultiClaimContract
  _disposers: IReactionDisposer[] = []

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.connected) this.loadData()
        },
        { fireImmediately: true }
      )
    ]
  }

  destroy() {
    this._disposers.forEach(d => d())
  }

  @asyncAction *loadData() {
    try {
      loadingController.increaseRequest()
      this.multiclaimHandler = blockchainHandler.multiclaimContractFactory({
        chainId: walletStore.chainId
      }) as SolidityMultiClaimContract
      this.multiclaimHandler.injectProvider()
      yield this.multiclaimHandler.init()
      yield this.loadPool()
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      loadingController.decreaseRequest()
    }
  }
  @asyncAction *loadPool() {
    this.claims = yield this.multiclaimHandler!.fetchClaimPoolsByOwner(walletStore.account!)
  }

  @action requestAddWinnerListDialog(value) {
    this.isShowAddWinnerListDialog = value
  }

  @computed get isVip() {
    return this.multiclaimHandler?.feeInfo?.isVip
  }
  @computed get txFee() {
    return this.multiclaimHandler?.feeInfo?.txFee
  }

  @asyncAction *addClaim(claimParam, configs) {
    const fee = this.isVip ? Zero : this.txFee || Zero
    yield this.multiclaimHandler?.addClaim(walletStore.account, claimParam, configs, fee.toString())
    yield this.loadPool()
  }

  @computed get validClaims() {
    const res = this.claims.filter((x: any) => !x.closed)
    return res.map((item: any) => {
      item.remaining = item.amount.isZero()
        ? FULL_100
        : item.amount
            .subUnsafe(item.claimedAmount)
            .divUnsafe(item.amount)
            .mulUnsafe(FULL_100)
      item.isCompleted = item.amount.isZero() ? false : item.amount.toString() === item.claimedAmount.toString()
      return item
    })
  }

  @action changeAddClaimDialogLoading(value) {
    this.addClaimDialogLoading = value
  }

  @computed get filteredPool() {
    const noFilter = !this.remainingFilter && !this.completedFilter
    const res =
      noFilter || (this.remainingFilter && this.completedFilter)
        ? this.validClaims
        : this.completedFilter
        ? this.validClaims.filter(item => item.isCompleted)
        : this.remainingFilter
        ? this.validClaims.filter(item => !item.isCompleted)
        : []
    return orderBy(res, 'createdDate', this.isShortAscCreatedDate ? 'asc' : 'desc')
  }

  @action.bound toggleCompletedFilter() {
    this.completedFilter = !this.completedFilter
  }
  @action.bound toggleRemainingFilter() {
    this.remainingFilter = !this.remainingFilter
  }
  @action.bound toggleSortCreatedDate() {
    this.isShortAscCreatedDate = !this.isShortAscCreatedDate
  }
}
